<template>
  <div class="Report">
    <!-- Page header -->
    <div class="PageHeader">
      <Button
        :text="mixWB('BACK')"
        class="Back"
        :path="{ name: 'ResultOverview', params: { screeningID: screeningID } }" />
    </div>

    <MissingThings />

    <div class="OldReportNotice">
      <span>{{ mixWB('W: Denne rapport er før version 1.14.0') }}</span>
    </div>

    <div class="PageList">
      <FrontPage />
      <TableOfContents
        :allPagesLoaded="allPagesLoaded"
        :pdf-type="'report'" />
      <GeneralInformation />
      <Units v-if="selectedUnits.length" />
      <Buildings v-else />
      <AnalysisResultsExplanationPage />
      <AnalysisResultsOverview :pdf-type="'report'" />
      <FloorPlans />
      <CategoryList />
      <CategoriesAndTypes />
      <FinalWords />
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/Buttons/Button.vue'
import MissingThings from '@/components/PDF/Report/MissingThings.vue'
import FrontPage from '@/components/PDF/Report/FrontPage.vue'
import TableOfContents from '@/components/PDF/TableOfContents.vue'
import GeneralInformation from '@/components/PDF/Report/GeneralInformation.vue'
import Units from '@/components/PDF/Report/Units.vue'
import Buildings from '@/components/PDF/Report/Buildings.vue'
import AnalysisResultsExplanationPage from '@/components/PDF/ReportPre1.14.0/AnalysisResultsExplanationPage'
import AnalysisResultsOverview from '@/components/PDF/ReportPre1.14.0/AnalysisResultsOverview.vue'
import FloorPlans from '@/components/PDF/Report/FloorPlans.vue'
import CategoryList from '@/components/PDF/CategoryList.vue'
import CategoriesAndTypes from '@/components/PDF/ReportPre1.14.0/CategoriesAndTypes.vue'
import FinalWords from '@/components/PDF/Report/FinalWords.vue'
import EventBus from '@/EventBus'
import { queryAllByClass } from '@/globals/javascript/_util/find-elements'

export default {
  name: 'Report',
  data() {
    return {
      assessmentPageCount: 0,
      extraBuildingPageCount: 0,
      extraTestResultPageCount: 0,
      areCategoriesAndTypesLoaded: false,
      isTestResultOverviewLoaded: false,
      pagesToBeLoaded: {
        analysisOverview: false,
        categoryTypes: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
    ]),
    allPagesLoaded() {
      const anyPageNotLoaded = Object.keys(this.pagesToBeLoaded).find(
        (key) => !this.pagesToBeLoaded[key],
      )

      return !anyPageNotLoaded
    },
    screeningID() {
      return this.$route.params.screeningID
    },
  },
  methods: {
    onPageLoaded(pageName) {
      this.pagesToBeLoaded[pageName] = true
    },
    onTestResultsExtraPages(number) {
      this.extraTestResultPageCount = number
      this.isTestResultOverviewLoaded = true
      this.updatePageNumbers()
    },
    onBuildingExtraPages(number) {
      this.extraBuildingPageCount = number
    },
    updatePageNumbers() {
      if (!this.areCategoriesAndTypesLoaded || !this.isTestResultOverviewLoaded) {
        return
      }

      const pages = queryAllByClass('AssessmentPage')
      if (!pages.length) {
        setTimeout(() => {
          this.onCategoriesAndTypesDoneLoading()
        }, 100)
        return
      }

      if (pages.length > this.assessmentPageCount) {
        this.assessmentPageCount = pages.length
        setTimeout(() => {
          this.onCategoriesAndTypesDoneLoading()
        }, 1000)
        return
      }

      // Update page number
      pages.forEach((pageNumber, index) => {
        pageNumber.innerHTML = `${
          this.mixWB('PAGE')
        } | ${
          8 + this.extraBuildingPageCount + this.extraTestResultPageCount + index
        }`
      })
    },
  },
  components: {
    Button,
    MissingThings,
    FrontPage,
    GeneralInformation,
    Units,
    Buildings,
    AnalysisResultsExplanationPage,
    AnalysisResultsOverview,
    FloorPlans,
    CategoryList,
    CategoriesAndTypes,
    FinalWords,
    TableOfContents,
  },
  created() {
    EventBus.$on('page-loaded', this.onPageLoaded)
  },
  destroyed() {
    EventBus.$off('page-loaded', this.onPageLoaded)
  },
}
</script>

<style lang="stylus" scoped>
  .Report
    display block

  .PageHeader
    width 794px
    margin 20px auto
    .Back
      display inline-block

  .OldReportNotice
    width 794px
    margin 20px auto
    span
      display block
      text-align center
      font-size 1.5rem
      font-weight bold

  @media print
    .PageHeader
      display none
    .OldReportNotice
      display none
</style>

<style lang="stylus">
  .PageReportPre1-14-0
    background-color $color_grey_lighter

  .PageList
    *
      font-family 'Montserrat', sans-serif
      font-size 0.75rem
      line-height 1.5
    span
      display block
</style>
